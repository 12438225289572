<template>
  <el-container class="cont">
    <el-aside class="mun" width="202">
      <el-menu
        class="mun-s"
        @select="menuClick"
        background-color="#001529"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="/">
          <span slot="title">首页</span>
        </el-menu-item>
        <div v-for="(item, index) in this.$router.options.routes" :key="index">
          <el-submenu :index="item.path" v-if="!item.hidden">
            <template slot="title">{{ item.name }}</template>
            <el-menu-item-group>
              <div v-for="(child, indexj) in item.children" :key="indexj">
                <el-menu-item :index="child.path" v-if="!child.hidden">{{
                  child.name
                }}</el-menu-item>
              </div>
            </el-menu-item-group>
          </el-submenu>
        </div>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{
            this.$router.currentRoute.name
          }}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-dropdown trigger="click">
          <span>{{ name }}</span>
          <el-dropdown-menu>
            <el-dropdown-item
              ><div @click="menuClick('/changePwd')">
                修改密码
              </div></el-dropdown-item
            >
            <el-dropdown-item>
              <div @click="logout()">退出登录</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>
      <el-main style="overflow: auto">
        <el-row :gutter="12" v-if="this.$router.currentRoute.path == '/'">
          <el-col :span="6">
            <el-card shadow="always"> 访问量 {{ visit_count }}</el-card>
          </el-col>
        </el-row>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      money_count: 0,
      user_count: 0,
      visit_count: 0,
      search_count: 0,
      export_count: 0,
      order_count: 0,
      gaode_count: 0,
      tengxun_count: 0,
      baidu_count: 0,
      shunqiwang_count: 0,
      url: "/api.Search/",
    };
  },
  computed: {
    name() {
      return "超级管理员";
    },
  },
  mounted() {
    //this.getCountAll();
  },
  methods: {
    menuClick(index) {
      this.$router.push(index);
    },
    getCountAll() {
      let _this = this;
      _this.postRequest(_this.url + "countAll").then((resp) => {
        _this.money_count = resp.data.money_count;
        _this.user_count = resp.data.user_count;
        _this.visit_count = resp.data.visit_count;
        _this.search_count = resp.data.search_count;
        _this.export_count = resp.data.export_count;
        _this.order_count = resp.data.order_count;
        _this.gaode_count = resp.data.gaode_count;
        _this.baidu_count = resp.data.baidu_count;
        _this.tengxun_count = resp.data.tengxun_count;
        _this.shunqiwang_count = resp.data.shunqiwang_count;
      });
    },
    logout() {
      this.$store.commit("INIT_TOKEN", "");
      this.$store.commit("INIT_TITLE", "");
      this.$message({
        type: "success",
        message: "退出成功",
      });
      let _this = this;
      setTimeout(function () {
        _this.$router.push("/login");
      }, 1500);
    },
  },
};
</script>
<style scoped>
.el-breadcrumb {
  line-height: 80px;
}

.cont {
  height: 100%;
  overflow: hidden;
}

.mun {
  background-color: rgb(238, 241, 246);
  overflow: hidden;
  border-right: 1px solid #ccc;
  width: 200px;
}

.mun-s {
  overflow-y: scroll;
  height: 100%;
  margin-right: -19px;
}

.header {
  line-height: 80px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.size {
  width: 100%;
  height: 100%;
}

.el-aside {
  color: #333;
}

.homeRouterView {
  margin-top: 10px;
}
</style>
