import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: window.sessionStorage.getItem("token"),
    title: window.sessionStorage.getItem("title"),
  },
  getters: {
    GET_TOKEN: (state) => state.token,
    GET_TITLE: (state) => state.title,
  },
  mutations: {
    INIT_TOKEN(state, token) {
      state.token = token;
      window.sessionStorage.setItem("token", token);
    },
    INIT_TITLE(state, title) {
      state.title = title;
      window.sessionStorage.setItem("title", title);
    },
  },
  actions: {},
  modules: {},
});
