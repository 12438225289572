<template>
  <div>
    <el-form
      :rules="rules"
      ref="loginForm"
      v-loading="loading"
      element-loading-text="正在登录..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :model="loginForm"
      class="loginContainer"
    >
      <h3 class="loginTitle">系统登录</h3>
      <el-form-item prop="username">
        <el-input
          size="normal"
          type="text"
          v-model="loginForm.username"
          auto-complete="off"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          size="normal"
          type="password"
          v-model="loginForm.password"
          auto-complete="off"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input
          size="normal"
          type="text"
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="点击图片更换验证码"
          @keydown.enter.native="submitLogin"
          style="width: 250px"
        ></el-input>
        <img
          :src="vcUrl"
          @click="updateVerifyCode"
          style="cursor: pointer; height: 40px; width: 200px"
        />
      </el-form-item>
      <el-checkbox size="normal" class="loginRemember" v-model="checked"
        >记住密码</el-checkbox
      >
      <el-button
        size="normal"
        type="primary"
        style="width: 100%"
        @click="submitLogin"
        >登录</el-button
      >
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      vcUrl: "/admin/Login/verifyCode?time=" + new Date(),
      loginForm: {
        username: "",
        password: "",
        code: "",
      },
      checked: true,
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getCookie();
  },
  methods: {
    updateVerifyCode() {
      this.vcUrl = "/admin/Login/verifyCode?time=" + new Date();
    },

    submitLogin() {
      let _this = this;
      _this.$refs.loginForm.validate((valid) => {
        if (valid) {
          _this.loading = true;
          _this.postRequest("/Login/doLogin", _this.loginForm).then((resp) => {
            _this.loading = false;
            if (resp.code == 200) {
              _this.$store.commit("INIT_TOKEN", resp.data.token);
              _this.$store.commit("INIT_TITLE", resp.data.title);
              if (_this.checked) {
                _this.setCookie(
                  _this.loginForm.username,
                  _this.loginForm.password
                );
              }
              let path = _this.$route.query.redirect;
              _this.$router.replace(
                path == "/" || path == undefined ? "/" : path
              );
            } else {
              _this.$message({
                type: "error",
                message: resp.msg,
              });
              _this.vcUrl = "/admin/Login/verifyCode?time=" + new Date();
            }
          });
        } else {
          return false;
        }
      });
    },
    setCookie(username, password) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdate); //保存的天数
      window.document.cookie =
        "username" + "=" + username + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "password" + "=" + password + ";path=/;expires=" + exdate.toGMTString();
    },
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); //再次切割
          //判断查找相对应的值
          if (arr2[0] == "username") {
            this.loginForm.username = arr2[1]; //保存到保存数据的地方
          } else if (arr2[0] == "password") {
            this.loginForm.password = arr2[1];
          }
        }
      }
    },
  },
};
</script>

<style>
.loginContainer {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 180px auto;
  width: 350px;
  padding: 15px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.loginTitle {
  margin: 15px auto 20px auto;
  text-align: center;
  color: #505458;
}

.loginRemember {
  text-align: left;
  margin: 0px 0px 15px 0px;
}

.el-form-item__content {
  display: flex;
  align-items: center;
}
</style>
