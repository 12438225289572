import axios from "axios";
import { Message } from "element-ui";
import router from "../router";
import store from "../store";
axios.interceptors.response.use(
  (success) => {
    return success.data;
  },
  (error) => {
    if (error.response.status == 504 || error.response.status == 404) {
      Message.error({ message: "服务器被吃了( ╯□╰ )" });
    } else if (error.response.status == 403) {
      Message.error({ message: "权限不足，请联系管理员" });
    } else if (error.response.status == 401) {
      router.replace("/");
    } else {
      if (error.response.data.msg) {
        Message.error({ message: error.response.data.msg });
      } else {
        Message.error({ message: "未知错误!" });
      }
    }
    return;
  }
);

let base = "/admin";

export const postKeyValueRequest = (url, params) => {
  return axios({
    method: "post",
    url: `${base}${url}`,
    data: params,
    transformRequest: [
      function (data) {
        let ret = "";
        for (let i in data) {
          ret +=
            encodeURIComponent(i) + "=" + encodeURIComponent(data[i]) + "&";
        }
        return ret;
      },
    ],
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Request-Token": store.getters.GET_TOKEN,
    },
  });
};
export const postRequest = (url, params) => {
  return axios({
    method: "post",
    url: `${base}${url}`,
    data: params,
    headers: {
      "Login-Token": store.getters.GET_TOKEN,
    },
  });
};
export const putRequest = (url, params) => {
  return axios({
    method: "put",
    url: `${base}${url}`,
    data: params,
    headers: {
      "Login-Token": store.getters.GET_TOKEN,
    },
  });
};
export const getRequest = (url, params) => {
  return axios({
    method: "get",
    url: `${base}${url}`,
    params: params,
    headers: {
      "Login-Token": store.getters.GET_TOKEN,
    },
  });
};
export const deleteRequest = (url, params) => {
  return axios({
    method: "delete",
    url: `${base}${url}`,
    params: params,
    headers: {
      "Login-Token": store.getters.GET_TOKEN,
    },
  });
};
