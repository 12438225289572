import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import axios from "axios";
import store from "../store";
import { Message } from "element-ui";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: Home,
    hidden: true,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    hidden: true,
    meta: {
      requiresAuth: false,
    },
  },
  // {
  //   path: "/jichu",
  //   name: "基础管理",
  //   component: Home,
  //   children: [
  //     {
  //       path: "/config",
  //       name: "基础设置",
  //       component: () => import("../views/pages/data/configs.vue"),
  //     },
     
  //   ],
  // },
  {
    path: "/neirong",
    name: "内容管理",
    component: Home,
    children: [
      {
        path: "/nav",
        name: "导航管理",
        component: () => import("../views/pages/data/nav.vue"),
      },

      {
        path: "/cate",
        name: "分类管理",
        component: () => import("../views/pages/data/cate.vue"),
      },
      {
        path: "/tupian",
        name: "图片管理",
        component: () => import("../views/pages/data/tupian.vue"),
      },
      {
        path: "/banner",
        name: "轮播图管理",
        component: () => import("../views/pages/data/banner.vue"),
      },
    ],
  },


];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.path != "/login") {
    let data = {
      token: store.getters.GET_TOKEN,
    };

    axios
      .post("/admin/Login/checkToken", data)
      .then(function (res) {
        if (res.code != 200) {
          next({
            path: "/login",
          });
        } else {
          next();
        }
      })
      .catch(function (error) {
        Message.error({
          message: error,
        });
      });
  } else {
    next();
  }
});
export default router;
